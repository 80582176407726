/**
 * React hook to simplify state management for a form.
 *
 * To use this, your form input's name should match the corresponding FormValue
 * key that is changed by that input. This makes sure that the
 * validator/formatter has additional context around which field was changed or
 * interacted with by the user.
 *
 * Adapted from:
 * https://upmostly.com/tutorials/using-custom-react-hooks-simplify-forms
 * https://upmostly.com/tutorials/form-validation-using-custom-react-hooks
 */

import { useState, useEffect } from "react";

import { trackEvent } from "src/utility/analytics";

export enum FormEvent {
  BLUR,
  CHANGE,
}

interface InternalFormArgs<FormValues> {
  /**
   * What kind of form event was last detected, if any.
   */
  formEvent?: FormEvent;

  /**
   * The last event target name/form value, if any.
   */
  formValueKey?: keyof FormValues;
}

interface FormArgs<FormValues, FormErrors> {
  /**
   * Function to call when the user submits the form.
   */
  submit?: () => void;

  /**
   * Function to call to validate the form values when they change, which
   * returns errors.
   */
  validateChange?: (values: FormValues) => FormErrors;

  /**
   * Function to call to validate the form values onBlur (when the input loses
   * focus) of the values, which returns errors.
   * @param formValueKey The blurred form value key, equal to the
   */
  validateBlur?: (
    values: FormValues,
    formValueKey: keyof FormValues
  ) => FormErrors;

  /**
   * Function to call to format the form values.
   */
  format: (
    values: FormValues,

    /**
     * The key of the form value that was changed.
     */
    formValueKey?: keyof FormValues,

    /**
     * The form event that caused the value change, if any.
     */
    formEvent?: FormEvent
  ) => FormValues;

  /**
   * Initial form values.
   */
  initialValues: FormValues;
}
export function useForm<FormValues extends object, FormErrors>({
  submit,
  validateChange,
  validateBlur,
  format,
  initialValues,
}: FormArgs<FormValues, FormErrors>): {
  /**
   * Object containing all the values from the form.
   */
  formValues: FormValues;

  /**
   * Object containing all the errors from the form.
   */
  formErrors: FormErrors;

  /**
   * Handler for form submits.
   * TODO: Add submit validations here.
   */
  handleFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;

  /**
   * Handler for form changes. By attaching this to inputs, it will validate and
   * format the changes and update values.
   */
  handleFormChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

  /**
   * Handler for form blurs. By attaching this to inputs, it will validate and
   * format the form values on blur.
   */
  handleFormBlur: (event: React.FocusEvent<HTMLInputElement>) => void;

  /**
   * Setter to manually update values. It will format and validate the value change.
   */
  setValues: (
    valuesToUpdate: Partial<FormValues>,
    formValueKey?: keyof FormValues
  ) => void;
} {
  const [values, setValues] = useState<
    FormValues & InternalFormArgs<FormValues>
  >(format(initialValues));
  const [errors, setErrors] = useState<FormErrors>({} as FormErrors);

  // Whenever values change, validates those changes.
  useEffect(() => {
    const { formEvent, formValueKey } = values;
    if (formEvent === FormEvent.BLUR) {
      if (validateBlur && formValueKey) {
        const errors = validateBlur(values, formValueKey);
        setErrors(errors);
      }
    } else if (validateChange) {
      const errors = validateChange(values);
      setErrors(errors);
    }
  }, [validateBlur, validateChange, values]);

  const setValuesExternal = (
    valuesToUpdate: Partial<FormValues>,
    formValueKey?: keyof FormValues
  ) => {
    trackEvent("UseForm - Set Values", {
      formKey: formValueKey,
    });
    setValues((oldValues) => {
      const newValues = {
        ...oldValues,
        ...valuesToUpdate,
      };
      return {
        ...format(newValues, formValueKey),
        formEvent: undefined,
        eventTargetName: formValueKey,
      };
    });
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (submit) {
      submit();
    }
  };

  const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Persist the event so that the event.target is retained for the React Hook callback.
    event.persist();
    setValues((values) => {
      const formValueKey = event.target.name as keyof FormValues;
      return {
        ...format(
          {
            ...values,
            [formValueKey]: event.target.value,
          },
          formValueKey,
          FormEvent.CHANGE
        ),
        formEvent: FormEvent.CHANGE,
        formValueKey,
      };
    });
  };

  const handleFormBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    // Persist the event so that the event.target is retained for the React Hook callback.
    event.persist();
    setValues((values) => {
      const formValueKey = event.target.name as keyof FormValues;
      trackEvent("Use Form - Exited Form Field", {
        formKey: formValueKey,
      });
      return {
        ...format(
          {
            ...values,
            [event.target.name]: event.target.value,
          },
          formValueKey,
          FormEvent.BLUR
        ),
        formEvent: FormEvent.BLUR,
        formValueKey,
      };
    });
  };

  return {
    handleFormSubmit,
    handleFormChange,
    handleFormBlur,
    formValues: values,
    formErrors: errors,
    setValues: setValuesExternal,
  };
}
