import { toName } from "@every.org/common/src/codecs/text";

/**
 * Formatter methods for user profile information.
 */

interface FormatNameArgs {
  firstName?: string;
  lastName?: string;
}

/**
 * Formats first and last name.
 */
export function formatNameValues({ firstName, lastName }: FormatNameArgs) {
  return {
    firstName: toName(firstName),
    lastName: toName(lastName),
  };
}
