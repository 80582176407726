import { formatNameValues } from "@components/User/UserProfileFormatter";

import { removeUndefinedValues } from "@every.org/common/src/helpers/objectUtilities";

import { FormEvent } from "src/hooks/useForm";
import { UserProfileFormValues } from "src/utility/signup/UserProfileTypes";

/**
 * Formats the sign up form values and returns the formatted values.
 */
export function formatSignupValues(
  values: UserProfileFormValues,
  formValueKey?: keyof UserProfileFormValues,
  formEvent?: FormEvent
): UserProfileFormValues {
  return removeUndefinedValues({
    ...values,
    ...formatNameValuesAfterTyping(values, formValueKey, formEvent),
  });
}

/**
 * Formats the sign up form values and returns the formatted values.
 */
export function formatNameValuesAfterTyping(
  values: UserProfileFormValues,
  formValueKey?: keyof UserProfileFormValues,
  formEvent?: FormEvent
): UserProfileFormValues {
  const { firstName, lastName } = values;
  return removeUndefinedValues({
    ...values,

    // Trim names when name changes except on CHANGE, as the user may not be
    // finished typing.
    ...((formValueKey === "firstName" || formValueKey === "lastName") &&
      formEvent !== FormEvent.CHANGE &&
      formatNameValues({ firstName, lastName })),
  });
}
