import {
  Button,
  ButtonRole,
  ButtonSize,
  ButtonTargetKind,
} from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import { Form } from "@components/Form";
import { Loading } from "@components/LoadingIndicator";
import { PasswordInput } from "@components/PasswordInput";
import { ExistingUserLoginLink } from "@components/QuestionAnswerLink";
import { FullNameSection } from "@components/Signup/BuildProfileSteps";
import { formatSignupValues } from "@components/Signup/SignupFormatter";
import { TextInputType, TextInput } from "@components/TextInput";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { UUID as uuidCodec } from "io-ts-types/UUID";
import React, { useState, useEffect, useCallback, useContext } from "react";

import { decodeOrUndefined } from "@every.org/common/src/codecs/index";
import { removeUndefinedOrNullValues } from "@every.org/common/src/helpers/objectUtilities";
import {
  createMeRouteSpec,
  checkEmailRouteSpec,
  MIN_PASSWORD_LENGTH,
} from "@every.org/common/src/routes/me";

import { AuthContext } from "src/context/AuthContext";
import { getWebAuth } from "src/context/AuthContext/WebAuth";
import {
  emailAuth0Login,
  EmailAuth0LoginStatus,
} from "src/context/AuthContext/actions";
import { useLoggedInOrGuestUserOrUndefined } from "src/context/AuthContext/hooks";
import { getMessageForError } from "src/errors";
import { useEdoRouter } from "src/hooks/useEdoRouter";
import { useForm } from "src/hooks/useForm";
import { cssForMediaSize, MediaSize } from "src/theme/mediaQueries";
import { spacing, verticalStackCss } from "src/theme/spacing";
import { getTestingId } from "src/utility/abtesting";
import { aliasMixpanelUserId } from "src/utility/analytics";
import { queryApi } from "src/utility/apiClient";
import { getUTMValuesFromCookie } from "src/utility/cookies";
import {
  UserProfileFormValues,
  UserProfileFormErrors,
} from "src/utility/signup/UserProfileTypes";
import { validateChange, validateBlur } from "src/utility/signup/validator";
import { moveUrlParameters } from "src/utility/url";

const FormBody = styled.div`
  ${verticalStackCss.m};
  padding-bottom: ${spacing.m};

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      ${verticalStackCss.l};
      padding-bottom: ${spacing.l};
    `,
  })}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: ${spacing.s};

  & > button {
    width: 100%;
  }

  ${cssForMediaSize({
    min: MediaSize.MEDIUM,
    css: css`
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: ${spacing.m};

      & > button {
        width: auto;
      }
    `,
  })}
`;

interface EmailSignupFormProps {
  isModal?: boolean;
  redirectUrl?: string;
  condensedSignUpFlow?: boolean;
  skipWelcomeModal?: boolean;
  isAdminRequest?: boolean;
}

/**
 * Performs signup through email using Auth0 via the CREATE_ME route.
 */
export const EmailSignupForm: React.FCC<EmailSignupFormProps> = ({
  isModal,
  redirectUrl,
  condensedSignUpFlow,
  skipWelcomeModal,
  isAdminRequest,
}) => {
  const { search } = useEdoRouter();
  const urlSearchParams = new URLSearchParams(search);

  useEffect(() => {
    getWebAuth(); // trigger it to be loaded before user hits login
  }, []);
  const loggedInUser = useLoggedInOrGuestUserOrUndefined();
  const authState = useContext(AuthContext);
  const abTestingId = getTestingId({ authState, createIdIfMissing: false });
  const { formValues, formErrors, handleFormChange, handleFormBlur } = useForm<
    UserProfileFormValues,
    UserProfileFormErrors
  >({
    validateChange,
    validateBlur,
    format: formatSignupValues,
    initialValues: {
      username: "",
      firstName: "",
      lastName: "",
      legalName: null,
      email: urlSearchParams.get("email") || loggedInUser?.email || "",
      isPrivate: false,
      publicDonationAmount: false,
      causes: new Set(),
      twitterHandle: null,
      facebookHandle: null,
      instagramHandle: null,
      linkedInHandle: null,
      youtubeHandle: null,
      tagMeOnSocial: true,
    },
  });

  const [serverErrors, setServerErrors] = useState<UserProfileFormErrors>({});
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [signedUp, setSignedUp] = useState(false);

  const errors = {
    ...serverErrors,
    ...formErrors,
  };

  // Build the redirect URL for post login, add the invite token and nonce if there was one in the original URL
  const { newToUrl: unifiedRedirectUrl } = moveUrlParameters({
    fromUrl: search,
    toUrl: urlSearchParams.get("redirectUrl") || redirectUrl,
    paramNames: ["inviteToken", "nonce"],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFormChange(event);
    // If a user modifies a field after a server error, we clear the errors.
    if (Object.keys(serverErrors).length > 0) {
      setServerErrors({});
    }
  };

  const { email, password, firstName, lastName } = formValues;

  const validateAccountInfo = useCallback(
    async function () {
      if (!email || errors.email || errors.password) {
        return false;
      }

      try {
        const emailIsAvailable = await queryApi(checkEmailRouteSpec, {
          body: { email },
          routeTokens: {},
          queryParams: {},
        });
        if (emailIsAvailable) {
          return true;
        }
        setServerErrors({ email: "This email is already registered." });
        return false;
      } catch (e) {
        setServerErrors({
          email: "Could not check if email is available. Please try again.",
        });
        return false;
      }
    },
    [email, errors.email, errors.password]
  );

  const submit = useCallback(
    async function () {
      setSubmitting(true);
      setSubmitted(true);
      if (!email || !password) {
        setServerErrors({ submit: "Please fill out all required fields." });
        setSubmitting(false);

        return;
      }
      if (!(await validateAccountInfo())) {
        setSubmitting(false);
        return;
      }

      const urlSearchParams = new URLSearchParams(search);

      try {
        const newUser = await queryApi(createMeRouteSpec, {
          body: removeUndefinedOrNullValues({
            firstName,
            lastName,
            email,
            password,
            metadata: getUTMValuesFromCookie(),
            guestToken: urlSearchParams.get("guestToken") || undefined,
            adminInviteToken: urlSearchParams.get("inviteToken") || undefined,
            requestAdminNonprofitId: decodeOrUndefined(
              uuidCodec,
              urlSearchParams.get("adminRequest")
            ),
            abTestingId,
          }),
          routeTokens: {},
          queryParams: {},
        });
        aliasMixpanelUserId(newUser.id);

        const auth0Result = await emailAuth0Login({
          email,
          password,
          redirectUrl: unifiedRedirectUrl,
          condensedSignUpFlow,
          skipWelcomeModal,
        });
        if (auth0Result.status === EmailAuth0LoginStatus.SUCCESS) {
          // Auth0 will redirect after login.
          setSignedUp(true);
          setSubmitting(false);
          return;
        }
        setSubmitting(false);
        setServerErrors({ submit: auth0Result.errorMessage });
      } catch (e) {
        if (e instanceof Error) {
          // TODO Better error printing.
          setServerErrors({ submit: getMessageForError(e) });
        }
        setSubmitting(false);
      }
    },
    [
      abTestingId,
      firstName,
      lastName,
      email,
      search,
      password,
      unifiedRedirectUrl,
      validateAccountInfo,
      condensedSignUpFlow,
      skipWelcomeModal,
    ]
  );

  if (signedUp) {
    // User will be redirected by Auth0 after a successful login.
    return <Loading text="Logging in..." />;
  }

  return (
    <Form
      borderless
      data-tname="signupCreateAccount"
      onSubmit={submit}
      submitButtonContent="Sign up"
      hideActions
      limitToInputWidth
    >
      <FormBody>
        <FullNameSection
          values={formValues}
          handleChange={handleChange}
          handleBlur={handleFormBlur}
        />
        <TextInput
          data-tname="Signup-EmailInput"
          type={TextInputType.EMAIL}
          labelText="Email"
          name="email"
          id="email"
          autoComplete="email"
          value={email}
          onChange={handleChange}
          collapseDescriptionSpace
          validationStatus={
            submitted && errors.email
              ? { success: false, message: errors.email }
              : undefined
          }
          required
          description={
            isAdminRequest
              ? "Please use your real name and nonprofit work email to create this account. It will help us verify your admin account faster."
              : undefined
          }
        />
        <PasswordInput
          data-tname="Signup-PasswordInput"
          autoComplete="new-password"
          name="password"
          labelText="Password"
          placeholder={`${MIN_PASSWORD_LENGTH}+ characters`}
          onChange={handleChange}
          value={password}
          collapseDescriptionSpace
          validationStatus={
            submitted && errors.password
              ? { success: false, message: errors.password }
              : undefined
          }
          required
        />
      </FormBody>
      <ButtonsContainer>
        <ExistingUserLoginLink redirectUrl={unifiedRedirectUrl} />
        <Button
          data-tname="signupCreateAccount-submit"
          role={ButtonRole.PRIMARY}
          size={ButtonSize.MEDIUM}
          onClick={{ kind: ButtonTargetKind.SUBMIT }}
          disabled={submitting}
          submitting={submitting}
        >
          Sign up
        </Button>
      </ButtonsContainer>
      <ErrorMessage css={{ marginTop: spacing.xs }} text={errors.submit} />
    </Form>
  );
};
